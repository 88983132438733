<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="ID" prop="id" v-if="dataForm.id">
        <el-input
          v-model="dataForm.id"
          :disabled="true"
          placeholder="ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户" prop="userId">
        <el-select v-model="dataForm.userId" placeholder="用户">
          <el-option
            v-for="(item, index) in userList"
            :key="index"
            :label="item.realName"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="组织类型" prop="orgType">
        <el-radio-group v-model="dataForm.orgType">
          <el-radio :label="0">供应商</el-radio>
          <el-radio :label="1">代理商&分销</el-radio>
          <el-radio :label="2">投资商</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供应商" prop="orgId">
        <el-select v-model="dataForm.orgId" placeholder="供应商">
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList, getUserList } from '@/utils/options.js';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      userList: [],
      dataForm: {
        id: 0,
        userId: '',
        orgType: '',
        orgId: '',
      },
      dataRule: {
        name: [
          { required: true, message: '供应商名字不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {},
  methods: {
    init(data, disabled) {
      this.disabled = disabled;
      this.getOrgList();
      this.getUserList();
      if (data !== null) {
        this.dataForm.id = data.id;
        this.dataForm.userId = data.userId;
        this.dataForm.orgType = data.orgType;
        this.dataForm.orgId = data.orgId;
      } else {
        this.dataForm.id = 0;
        this.dataForm.userId = '';
        this.dataForm.orgType = '';
        this.dataForm.orgId = '';
      }

      this.visible = true;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/sys-user/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getUserList() {
      getUserList().then(({ data }) => {
        if (data && data.code === 0) {
          this.userList = data.list;
        }
      });
    },
  },
};
</script>
